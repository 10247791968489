import Image from 'next/image';
import Link from 'next/link';

export const Footer = () => {
  return (
    <div className="bg-[#283859]">
      <div className="container mx-auto p-5 md:py-10 lg:px-4">
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between ">
          <div className="flex flex-col md:w-full lg:ml-14 lg:w-[85%] ">
            <div className="flex items-center gap-x-4 font-medium tracking-wide text-gray-100 md:justify-center lg:justify-start">
              <Link href="/">
                <span>
                  <Image
                    src="/images/logo-blue.svg"
                    alt="AbleSpace Logo"
                    className="h-7 w-7"
                    width={28}
                    height={28}
                  />
                </span>
              </Link>
              <span className="py-3">ABLESPACE</span>
            </div>

            {/* feature and more links */}
            <div className="mt-5 flex h-64 flex-col flex-wrap gap-x-24 gap-y-5 text-white md:h-full md:w-full md:flex-row md:justify-center md:gap-x-8 lg:flex-row lg:justify-start xl:w-[85%]">
              <Link href="/features">
                <span className="text-base">Features</span>
              </Link>
              <Link href="/tutorials">
                <span className="text-base">Tutorials</span>
              </Link>
              <Link href="/blog">
                <span className="text-base">Blog</span>
              </Link>
              <Link href="/hippa">
                <span className="text-base">HIPPA</span>
              </Link>
              <Link href="/ferpa">
                <span className="text-base">FERPA</span>
              </Link>
              <Link href="/reviews">
                <span className="text-base">Reviews</span>
              </Link>
              <Link href="/faqs">
                <span className="text-base">FAQ</span>
              </Link>
              <Link href="/schools-and-districts">
                <span className="text-base">Schools/Districts</span>
              </Link>
              <Link href="/enterprise">
                <span className="text-base text-white">Enterprises</span>
              </Link>
              <Link href="/privacy-policy">
                <span className="text-base text-white">Privacy Policy</span>
              </Link>
              <Link href="/terms-of-service">
                <span className="text-base text-white">Terms of Service</span>
              </Link>
            </div>
          </div>

          {/* App Redirect Links */}
          <div className="mt-5 flex items-center justify-center gap-4 lg:mt-0 lg:w-6/12 lg:justify-center">
            <Link href="https://play.google.com/store/apps/details?id=io.ablespace.androidapp">
              {/* <span className="mr-4 flex items-center justify-center rounded-[12px] bg-white px-1 py-4 md:px-[6px] md:py-[12px] lg:px-[8px] lg:py-[12px]"> */}
              <Image
                src="/images/icons/appstoreredirect/android/native-google-play.svg"
                width={200}
                height={0}
                alt="Google Play"
              />
              {/* <div className="mr-2 w-[12%]">
                  <OptimisedImage
                    defaultImagePath="/images/icons/appstoreredirect/androidicon/googleplay-icon.png"
                    data={[
                      {
                        imagePath:
                          '/images/icons/appstoreredirect/androidicon/googleplay-icon.png',
                        imageWidth: 0,
                        alt: 'Google Play',
                      },
                    ]}
                  />
                </div>
                <span className="text-[16px] text-[#3d3e40] md:text-[20px] ">
                  Google Play
                </span> */}
              {/* </span> */}
            </Link>

            <Link href="https://apps.apple.com/us/app/ablespace-iep-goal-tracking/id1662372988">
              {/* <span className="flex items-center justify-center rounded-[12px] bg-white px-2 py-4 md:px-[13px] md:py-[12px] lg:px-[16px] lg:py-[12px]"> */}
              <Image
                src="/images/icons/appstoreredirect/ios/native-ios-store.svg"
                width={200}
                height={0}
                alt="App Store"
              />
              {/* <div className="mr-2 w-[12%]">
                  <OptimisedImage
                    defaultImagePath="/images/icons/appstoreredirect/iosicon/iosstore-icon.png"
                    data={[
                      {
                        imagePath:
                          '/images/icons/appstoreredirect/iosicon/iosstore-icon.png',
                        imageWidth: 0,
                        alt: 'App Store',
                      },
                    ]}
                  />
                </div>
                <span className="text-[16px] text-[#3d3e40] md:text-[20px]">
                  App Store
                </span> */}
              {/* </span> */}
            </Link>
          </div>
        </div>
      </div>

      {/* divider */}
      <div className="mx-auto mt-1 w-[90%] border border-[#3F598C]"></div>

      {/* social icons */}
      <div className="container mx-auto mt-4 w-[84%] pb-10">
        <div className="flex flex-col gap-4 md:w-full md:flex-row md:justify-between md:gap-0 md:p-0 md:py-5">
          <div className="flex items-center justify-center gap-x-4 md:order-2 md:self-end ">
            <a
              href="https://www.facebook.com/ablespace.io/"
              className="h-[28px] w-[28px]"
            >
              {/* <OptimisedImage
                defaultImagePath="/images/icons/social-media/facebook/Facebook@3x.png"
                data={[
                  {
                    imagePath:
                      '/images/icons/social-media/facebook/Facebook@3x.png',
                    imageWidth: 0,
                    alt: 'Facebook',
                  },
                ]}
              /> */}
              <Image
                src="/images/icons/social-media/facebook/Facebook@3x.png"
                width={28}
                height={28}
                alt="Facebook"
              />
            </a>
            <a
              href="https://www.instagram.com/ablespace.io/"
              className="h-[28px] w-[28px]"
            >
              {/* <OptimisedImage
                defaultImagePath="/images/icons/social-media/instagram/Instagram@3x.png"
                data={[
                  {
                    imagePath:
                      '/images/icons/social-media/instagram/Instagram@3x.png',
                    imageWidth: 0,
                    alt: 'Instagram',
                  },
                ]}
              /> */}
              <Image
                src="/images/icons/social-media/instagram/Instagram@3x.png"
                width={28}
                height={28}
                alt="Instagram"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UC3g6DELgtautJeke1tHVf6g/"
              className="h-[28px] w-[28px]"
            >
              {/* <OptimisedImage
                defaultImagePath="/images/icons/social-media/youtube/YouTube@3x.png"
                data={[
                  {
                    imagePath:
                      '/images/icons/social-media/youtube/YouTube@3x.png',
                    imageWidth: 0,
                    alt: 'Youtube',
                  },
                ]}
              /> */}
              <Image
                src="/images/icons/social-media/youtube/YouTube@3x.png"
                width={28}
                height={28}
                alt="Youtube"
              />
            </a>
          </div>
          <div className="text-center text-sm text-gray-200 md:order-1">
            © 2024 AbleSpace. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};
